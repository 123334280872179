import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {AuthService} from './_services/auth-service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, public authService: AuthService) { }
  return;

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.verifyAuth(evt.url);
      window.scrollTo(0, 0);
    });
  }

  verifyAuth(url) {
    if (url.indexOf('change') === 1) {
      return;
    }

    if (!this.authService.isLogged()) {
      this.router.navigate(['login']);
    }
  }
}
