import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {AuthService } from '../../_services/auth-service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidatorService } from '../../_services/validator-service';
import { Router } from '@angular/router';
import { ApiClientService } from '../../_services/swagger';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {

  public frm: FormGroup;
  public frmRecoverPass: FormGroup;
  private errorApi: any;
  private showLogin: boolean;
  private showRecoverPassword: boolean;
  public successMessage: string;
  public errorMessage: string;
  public loading = false;

  constructor(
    private authService: AuthService,
    public frmBuilder: FormBuilder,
    public _validator: ValidatorService,
    private router: Router,
    private _apiClient: ApiClientService
  ) {
    this.showLogin = true;
    this.showRecoverPassword = false;
  }

  ngOnInit() {
    if (this.authService.isLogged()) {
      this.redirectToHome();
    }

    this.form(this.frmBuilder);
  }

  form(frmBuilder) {
    /** Construye un formBuilder con sus validaciones para los input en la vista */
    this.frm = frmBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(6), Validators.maxLength(150)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(150)])]
    });
    this.frmRecoverPass = frmBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      confirm_email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  /** Inicia sesión desde la vista */
  login() {

      /** muestra un loading de autenticando */
      this._validator.validateFields(this.frm);

      /** Verifica si el formulario cumple con las validaciones */
      if (this.frm.valid) {
        this.loading = true;

        localStorage.clear();
        /** si no hay error en el formulario continua con la autentificacion */
        this.authService.login(this.frm.value)
        .then(() => {
          this.loading = false;
          this.redirectToHome();
        } )
        .catch(error => {
          this.errorApi = error.error.data;
          this.loading = false;

          if (!error.error.data) {
            this.errorApi = {
              email: [error.error.message]
            };
          }
          setTimeout(() => this.clearMessages(), 8000);
        });
      } else {
        /** Muestra alerta de completar información en el formulario */
        console.log('Error', 'Es necesario que ingreses bien tus credenciales');
      }
      // this.loading = false;
  }

  showRecoverPass() {
    const elements: NodeListOf<Element> = document.getElementsByClassName('user_options-forms');
    elements[0].classList.remove('bounceRight');
    elements[0].classList.add('bounceLeft');
    this.clearInputs();
    this.clearMessages();
  }

  fnShowLogin() {
    const elements: NodeListOf<Element> = document.getElementsByClassName('user_options-forms');
    elements[0].classList.add('bounceRight');
    elements[0].classList.remove('bounceLeft');
    this.clearInputs();
    this.clearMessages();
  }
  clearInputs() {
    (<HTMLInputElement>document.getElementById('email')).value = '';
    (<HTMLInputElement>document.getElementById('password')).value = '';
    (<HTMLInputElement>document.getElementById('email_recover')).value = '';
    (<HTMLInputElement>document.getElementById('email_confirm_recover')).value = '';
  }

  clearMessages() {
    this.successMessage = null;
    this.errorMessage = null;
    this.errorApi = null;
  }

  redirectToHome() {
    // redirecciona a inicio de app
    this.router.navigate(['/condominios']);
  }

}
