import { Injectable } from '@angular/core';
// Swagger Client API
import { ApiClientService } from './swagger';
import { AuthService } from './auth-service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorService {

    public error: string;

    constructor(private _apiClient: ApiClientService,
                private authService: AuthService,
                private _router: Router,
            ) {

            }

    verify(error) {
        if (error.status === 401) {
            this.authService.logout();
            this._router.navigate(['login']);
        }
    }
}
