 import { NgModule }      from '@angular/core';
 import { CapitalizeFirstPipe } from './capitalizefirst.pipe';
import {CommonModule} from "@angular/common";

 @NgModule({
     imports:        [CommonModule],
     declarations:   [CapitalizeFirstPipe],
     exports:        [CapitalizeFirstPipe],
 })

 export class MainPipeModule {

 } 