import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { LoginRequest, LoginResponse, LoginDataResponse, LoginValidationResponse, AllCondominiumResponse, CondominiumResponse, CreateUpdateCondominiumResponse, CondominiumValidateResponse, CreateCondominiumRequest, UpdateCondominiumRequest, AllAdminResponse, AdminResponse, CreateUpdateAdminResponse, AdminValidateResponse, CreateAdminRequest, UpdateAdminRequest, ChangePasswordRequest, ChangePasswordValidateResponse, AllCorteResponse, CorteResponse, CreateCorteResponse, AllCorteTotalResponse, AllCortesResponse, CortesResponse, AllResidentResponse, ResidentResponse, ResidentPDFResponse, ResidentImportExcelResponse, ResidentImportExcelValidateResponse, ResidentImportExcelRequest, NoDataResponse, DataResponse } from './models';

/**
* Created with angular4-swagger-client-generator.
*/
@Injectable()
export class ApiClientService {

  private domain = 'https://wsqa.frakcio.com/api/v1/superadmin';

  constructor(private http: HttpClient, @Optional() @Inject('domain') domain: string ) {
    if (domain) {
      this.domain = domain;
    }
  }

  /**
  * Method login
  * @param body The Datos de login
  * @return The full HTTP response as Observable
  */
  public login(body: LoginRequest): Observable<HttpResponse<LoginResponse>> {
    let uri = `/auth/login`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    return this.sendRequest<LoginResponse>('post', uri, headers, params, JSON.stringify(body));
  }

  /**
  * Method condominium
  * @param Authorization The Condominios Super Admin
  * @return The full HTTP response as Observable
  */
  public condominium(Authorization: string): Observable<HttpResponse<AllCondominiumResponse>> {
    let uri = `/condominium`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<AllCondominiumResponse>('get', uri, headers, params, null);
  }

  /**
  * Method createCondominium
  * @param Authorization The access token.
  * @param body The Datos del usuario administrador a registrar
  * @return The full HTTP response as Observable
  */
  public createCondominium(Authorization: string, body: CreateCondominiumRequest): Observable<HttpResponse<CreateUpdateCondominiumResponse>> {
    let uri = `/condominium`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<CreateUpdateCondominiumResponse>('post', uri, headers, params, JSON.stringify(body));
  }

  /**
  * Method updateCondominum
  * @param Authorization The Condominios Super Admin
  * @param body The Datos del usuario administrador a modificar
  * @return The full HTTP response as Observable
  */
  public updateCondominum(Authorization: string, body: UpdateCondominiumRequest): Observable<HttpResponse<CreateUpdateCondominiumResponse>> {
    let uri = `/condominium`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<CreateUpdateCondominiumResponse>('put', uri, headers, params, JSON.stringify(body));
  }

  /**
  * Method admin
  * @param Authorization The Condominios Super Admin
  * @return The full HTTP response as Observable
  */
  public admin(Authorization: string): Observable<HttpResponse<AllAdminResponse>> {
    let uri = `/admin`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<AllAdminResponse>('get', uri, headers, params, null);
  }

  /**
  * Method createAdmin
  * @param Authorization The access token.
  * @param body The Datos del usuario administrador a registrar
  * @return The full HTTP response as Observable
  */
  public createAdmin(Authorization: string, body: CreateAdminRequest): Observable<HttpResponse<CreateUpdateAdminResponse>> {
    let uri = `/admin`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<CreateUpdateAdminResponse>('post', uri, headers, params, JSON.stringify(body));
  }

  /**
  * Method updateAdmin
  * @param Authorization The Condominios Super Admin
  * @param body The Datos del usuario administrador a modificar
  * @return The full HTTP response as Observable
  */
  public updateAdmin(Authorization: string, body: UpdateAdminRequest): Observable<HttpResponse<CreateUpdateAdminResponse>> {
    let uri = `/admin`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<CreateUpdateAdminResponse>('put', uri, headers, params, JSON.stringify(body));
  }

  /**
  * Method deleteAdmin
  * @param Authorization The Condominios Super Admin
  * @param id The id del administrador a eliminar
  * @return The full HTTP response as Observable
  */
  public deleteAdmin(Authorization: string, id: number): Observable<HttpResponse<NoDataResponse>> {
    let uri = `/admin/${id}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<NoDataResponse>('delete', uri, headers, params, null);
  }

  /**
  * Method changePassword
  * @param Authorization The access token.
  * @param body The Datos del usuario administrador a registrar
  * @return The full HTTP response as Observable
  */
  public changePassword(Authorization: string, body: ChangePasswordRequest): Observable<HttpResponse<NoDataResponse>> {
    let uri = `/admin/changepassword`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<NoDataResponse>('put', uri, headers, params, JSON.stringify(body));
  }

  /**
  * Method corte
  * @param Authorization The Condominios Super Admin
  * @param idCondominium The ID del condominio
  * @param date1 The fecha 1
  * @param date2 The fecha 2
  * @return The full HTTP response as Observable
  */
  public corte(Authorization: string, idCondominium: number, date1: string, date2: string): Observable<HttpResponse<AllCorteResponse>> {
    let uri = `/corte/${idCondominium}/${date1}/${date2}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<AllCorteResponse>('get', uri, headers, params, null);
  }

  /**
  * Method createCorte
  * @param Authorization The access token.
  * @param idCondominium The ID del condominio
  * @param date1 The ID del condominio
  * @param date2 The ID del condominio
  * @return The full HTTP response as Observable
  */
  public createCorte(Authorization: string, idCondominium: number, date1: string, date2: string): Observable<HttpResponse<CreateCorteResponse>> {
    let uri = `/corte/${idCondominium}/${date1}/${date2}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<CreateCorteResponse>('post', uri, headers, params, null);
  }

  /**
  * Method cortetotal
  * @param Authorization The Condominios Super Admin
  * @param idCondominium The ID del condominio
  * @param date1 The fecha 1
  * @param date2 The fecha 2
  * @return The full HTTP response as Observable
  */
  public cortetotal(Authorization: string, idCondominium: number, date1: string, date2: string): Observable<HttpResponse<AllCorteTotalResponse>> {
    let uri = `/corte/total/${idCondominium}/${date1}/${date2}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<AllCorteTotalResponse>('get', uri, headers, params, null);
  }

  /**
  * Method cortes
  * @param Authorization The Condominios Super Admin
  * @return The full HTTP response as Observable
  */
  public cortes(Authorization: string): Observable<HttpResponse<AllCortesResponse>> {
    let uri = `/cortes`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<AllCortesResponse>('get', uri, headers, params, null);
  }

  /**
  * Method resident
  * @param Authorization The Condominios Super Admin
  * @param idCondominium The ID del condominio
  * @return The full HTTP response as Observable
  */
  public resident(Authorization: string, idCondominium: number): Observable<HttpResponse<AllResidentResponse>> {
    let uri = `/resident/${idCondominium}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<AllResidentResponse>('get', uri, headers, params, null);
  }

  /**
  * Method residentpdf
  * @param Authorization The Condominios Super Admin
  * @param idCondominium The ID del condominio
  * @return The full HTTP response as Observable
  */
  public residentpdf(Authorization: string, idCondominium: number): Observable<HttpResponse<ResidentPDFResponse>> {
    let uri = `/resident/createinitialpdf/${idCondominium}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<ResidentPDFResponse>('get', uri, headers, params, null);
  }

  /**
  * Method residentimportexcel
  * @param Authorization The access token.
  * @param body The Excel a importar de residentes
  * @param idCondominium The ID del condominio
  * @return The full HTTP response as Observable
  */
  public residentimportexcel(Authorization: string, body: ResidentImportExcelRequest, idCondominium: number): Observable<HttpResponse<ResidentImportExcelResponse>> {
    let uri = `/resident/importexcel/${idCondominium}`;
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (Authorization) {
      headers = headers.set('Authorization', Authorization + '');
    }
    return this.sendRequest<ResidentImportExcelResponse>('post', uri, headers, params, JSON.stringify(body));
  }

  private sendRequest<T>(method: string, uri: string, headers: HttpHeaders, params: HttpParams, body: any): Observable<HttpResponse<T>> {
    if (method === 'get') {
      return this.http.get<T>(this.domain + uri, { headers: headers.set('Accept', 'application/json'), params: params, observe: 'response' });
    } else if (method === 'put') {
      return this.http.put<T>(this.domain + uri, body, { headers: headers.set('Content-Type', 'application/json'), params: params, observe: 'response' });
    } else if (method === 'post') {
      return this.http.post<T>(this.domain + uri, body, { headers: headers.set('Content-Type', 'application/json'), params: params, observe: 'response' });
    } else if (method === 'delete') {
      return this.http.delete<T>(this.domain + uri, { headers: headers, params: params, observe: 'response' });
    } else {
      console.error('Unsupported request: ' + method);
      return Observable.throw('Unsupported request: ' + method);
    }
  }
}
