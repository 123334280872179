import { Component, Input } from '@angular/core';
import { navItems } from '../../_nav';
import { AuthService } from '../../_services/auth-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public currentUser: any;
  public condominiumSelect: string;

  constructor(private authService: AuthService, private router: Router) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    /*const condominium = JSON.parse(localStorage.getItem('condominium'));
    console.log(this.condominiumSelect);
    this.condominiumSelect = condominium.name;
    console.log(this.condominiumSelect);*/
  }

  /*
  onChange(condominium) {
    const oCondominium = this.currentUser.condominiums.find(o => o.name === condominium);
    this.condominiumSelect = condominium;
    localStorage.setItem('condominium', JSON.stringify(oCondominium));
    this.router.navigate(['']);

  }*/

  logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }
}
